/* ++++++++   Utility Clases +++++++++  */

@font-face {
  font-family: "Louis-Geroge-Cafe";
  src: local("Louis-Geroge-Cafe"), url("./fonts/Louis-George-Cafe.ttf") format("truetype");
  font-weight: normal;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Louis-Geroge-Cafe";
}

html {
  font-family: "Louis-Geroge-Cafe";
}

body {
  font-family: "Louis-Geroge-Cafe";
}

:root {
  --backg-color: #0a1528;
  --white: #fff;
  --light-grey: #d2d2d2;
  --prime-heading: #9cbdeb;
  --prime-blue: #3cc3f2;
  --dark-grey: #707070;
  --orange-color: #ea7b2c;
  --off-white: #f4f7f8;
  --nav-color: #303c4c;
  --success-color: #609966;
  --error-color: #ff0303;

  /* font sizes used in project  */
  /* --xxs-font: 0.4vw;
  --xs-font: 0.6vw;
  --s-font: 0.85vw;
  --sm-font: 0.95vw;
  --m-font: 1vw;
  --ml-font: 1.2vw;
  --l-font: 1.4vw;
  --xl-font: 1.6vw;
  --xxl-font: 2vw;
  --xxxl-font: 2.2vw;
  --h-font: 2.5vw;
  --hl-font: 2.8vw;
  --hhl-font: 3vw;
  --hhhl-font: 3.2vw; */
  --xxs-font: 0.4vw;
  --xs-font: 0.6vw;
  --s-font: 0.85vw;
  --sm-font: 0.95vw;
  --m-font: 1vw;
  --ml-font: 1.2vw;
  --l-font: 1.4vw;
  --xl-font: 1.6vw;
  --xxl-font: 2vw;
  --xxxl-font: 2.2vw;
  --h-font: 2.5vw;
  --hl-font: 2.8vw;
  --hhl-font: 3vw;
  --hhhl-font: 3.2vw;
}

@media only screen and (max-width: 500px) {
  :root {
    --xxs-font: 2vw;
    --xs-font: 3vw;
    --s-font: 4vw;
    --sm-font: 5vw;
    --m-font: 6vw;
    --ml-font: 7vw;
    --l-font: 8vw;
    --xl-font: 9vw;
    --xxl-font: 10vw;
    --xxxl-font: 11vw;
  }
}
/* Font sizes for IPAD and IPAD Pro*/
@media only screen and (min-width: 700px) and (max-width: 780px) and (min-height: 1000px) and (max-height: 1050px),
  (min-width: 1020px) and (max-width: 1040px) and (min-height: 1350px) and (max-height: 1370px),
  (min-width: 500px) and (max-width: 550px) and (min-height: 700px) and (max-height: 730px) {
  :root {
    --xxs-font: 1vw;
    --xs-font: 1.5vw;
    --s-font: 2.5vw;
    --sm-font: 3vw;
    --m-font: 3.5vw;
    --ml-font: 4vw;
    --l-font: 4.5vw;
    --xl-font: 5vw;
    --xxl-font: 6vw;
    --xxxl-font: 7vw;
  }
}
.container-box {
  max-width: 85vw;
  margin: 0 auto;
}

.container-layout {
  max-width: 108rem;
  margin: 0 auto;
}

::-webkit-scrollbar {
  position: absolute;
  width: 5px;
  border-radius: 5px;
  background: transparent;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  border: none;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--light-grey);
}

/*
 media queries start here 
*/

@media (max-width: 1760px) {
  .container-layout {
    max-width: 102rem;
  }
}

@media (max-width: 1660px) {
  .container-layout {
    max-width: 95rem;
  }
}

@media (max-width: 1550px) {
  .container-layout {
    max-width: 86rem;
  }
}

@media (max-width: 1420px) {
  .container-layout {
    max-width: 80rem;
  }
}

@media (max-width: 1300px) {
  .container-layout {
    max-width: 75rem;
  }
}

@media (max-width: 1200px) {
  .container-layout {
    max-width: 70rem;
  }
}

@media (max-width: 1000px) {
  .container-layout {
    max-width: 58rem;
  }
}

.file-icon {
  position: relative;
  display: inline-block;
}

.file-icon-size {
  height: 4vh;
}
