@import "./../../../general.scss";

.part_level_table_data_container {
  padding-top: 2vh;
  width: 60vw;
  color: var(--white);
  text-align: center;

  .part_level_heading {
    cursor: pointer;
    @include common-font-style(var(--ml-font), var(--prime-blue), normal);
    text-align: center;
  }

  .part_level_button_group {
    display: flex;
    gap: 1vh;
  }

  .part_level_information_table {
    overflow-y: auto;
    width: 100%;
    padding-top: 2vh;

    .part_level_cols_data {
      @include common-font-style(var(--l-font), var(--prime-blue), normal);
      background-color: var(--backg-color);
    }
  }
}

.part_level_table_data_container_heading {
  @include common-font-style(var(--ml-font), var(--white), normal);
}
.part_level_table_data_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-btn {
  @extend .tool-btn;
  background-color: var(--prime-blue);
  font-size: var(--m-font);
}

.disabled-btn {
  @extend .tool-btn;
  background-color: var(--dark-grey);
  font-size: var(--m-font);
}
