@import "../../../../../../general.scss";

#history-section {
  padding: var(--xs-font) 0;
  overflow-y: scroll;
  min-height: 50vh;
  max-height: 75vh;
  overflow-x: hidden;
}

.history-item {
  @include grid-five-col(12vw, 23vw, 15vw, 18vw, 13vw);

  // border: 1px solid #707070;
  // background-color: var(--nav-color);
  border-radius: 1vh;
  padding: 1vh;
  margin-bottom: 10px;
  // opacity: 0.75;
  color: #fff;
  background: rgba(241, 247, 249, 0.15);
  backdrop-filter: blur(10px);

  h4 {
    @include common-font-style(var(--m-font), #fff, normal);
    color: #fff;
  }
  cursor: pointer;
}

.history-item h4:nth-child(3),
.history-item h4:nth-child(4),
.history-item h4:nth-child(5) {
  text-align: left;
}
