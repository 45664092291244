//  ++++++++  all mixins include here +++++++++  //
$borderRadiusSize: 4px;

@mixin common-font-style($fontsize, $color, $fontweight) {
  font-size: $fontsize;
  color: $color;
  font-weight: $fontweight;
  font-family: "Louis-Geroge-Cafe";
}

@mixin box-center {
  display: grid;
  place-items: center;
}

@mixin flex-prop($jc, $ai, $gap) {
  display: flex;
  justify-content: $jc;
  align-items: $ai;
  gap: $gap;
}

@mixin grid-two-col($size1, $size2) {
  display: grid;
  grid-template-columns: $size1 $size2;
}

@mixin grid-three-col($size1, $size2, $size3) {
  display: grid;
  grid-template-columns: $size1 $size2 $size3;
}

@mixin grid-four-col($contain1, $contain2, $contain3, $contain4) {
  display: grid;
  grid-template-columns: $contain1 $contain2 $contain3 $contain4;
}

@mixin grid-five-col($contain1, $contain2, $contain3, $contain4, $contain5) {
  display: grid;
  grid-template-columns: $contain1 $contain2 $contain3 $contain4 $contain5;
}

@mixin verticalFlex($gap-size: 1vh, $flexDirec: column) {
  position: relative;
  display: flex;
  flex-direction: $flexDirec;
  align-items: center;
  justify-self: center;
  gap: $gap-size;
}

@mixin horizontalFlex($gap-size: 1vh) {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: center;
  gap: $gap-size;
}

// @mixin verticalFlex($gap-size: 1vh, $justify: space-between, $align: center, $width: 100%) {
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   align-items: $align;
//   justify-content: $justify;
//   gap: $gap-size;
//   width: $width;
// }

// @mixin horizontalFlex($gap-size: 1vh, $justify: space-between, $align: center, $width: 100%) {
//   position: relative;
//   display: flex;
//   flex-direction: row;
//   align-items: $align;
//   justify-content: $justify;
//   gap: $gap-size;
//   width: $width;
// }

.navbar-container {
  // align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  background-color: var(--backg-color);
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.tool-btn {
  @include flex-prop(center, center, 0.5vw);
  text-align: center;
  padding: 1vh 2vh;
  border-radius: 5px;
  // background-color: var(--light-grey);
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: white;
  min-width: 6vw;
  width: fit-content;

  &:hover {
    opacity: 0.8;
  }
}

.tool-card-layout {
  position: relative;
  display: block;
  // overflow: auto;
  height: 100vh;
  width: 100vw;
  // padding: 1vh;
  // margin: 1vh;
  // border-radius: 1vh;
  background-color: var(--backg-color);
  // box-shadow: 0px 0px 4px 2px rgb(117 116 116 / 34%);
}

// select dropdown styles //
.select-container {
  @include verticalFlex($flexDirec: row);
  width: 100%;
  justify-content: space-between;
}

.select-form-select {
  width: 100%;
}

.select-form-label {
  display: flex;
  color: #9cbdeb;
  width: 100%;
  font-size: var(--ml-font);
}
