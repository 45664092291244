.count-info-container {
  height: 91.5vh;
  width: 100%;
  padding: 3vh 1vw;
  background-color: #0a1528;
  opacity: 1;

  .display_info {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    font-size: 1.5rem;
    color: white;
    gap: 4vh;

    .translated_info_container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      gap: 2vh;

      .translated_info {
        max-width: 100%;

        .translated_info_label {
          color: #9cbdeb;
          display: flex;
          align-items: center;
          gap: 1vh;
        }

        .translated_info_value {
          color: #fff;
          align-items: center;
          // position: relative;
          gap: 0.5vh;
          display: flex;
          max-width: 12vw;
          .show_tip {
            display: flex;
            position: relative;

            .info_icon {
              color: #3cc3f2;
              cursor: pointer;
              z-index: 1;
              padding: 0.5vh;
              font-size: var(--xxl-font);
            }
          }
        }
      }
    }
  }
}

.popup_box {
  display: flex;
  color: var(--white, #ffffff);
  background: #5f6e78;
  width: 10vw;
  border-radius: 1vh;
  position: absolute;
  padding: 0.5vh 0.3vh;
  // top: 11.71vw;
  // left: 31.8vw;
  .popup_box_content {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0 1vh 1vh 1vh;

    .popup_label {
      color: #9cbdeb;
      display: flex;
      gap: 1vh;
      padding: 0.5vh 0 2.5vh 2vh;
      padding-left: 2.5vh;
      font-size: var(--m-font);
      padding-bottom: 2vh;
    }
  }
}
.active-style {
  color: var(--white);
}

.translated_info_section {
  display: flex;
  justify-content: space-between;
  width: 46vw;
}

.count-info-file-name {
  max-width: 46vw;
  min-width: 46vw;
  .count-info-file-title {
    color: var(--prime-heading);
  }
}

.popup-item {
  text-transform: capitalize;
  font-size: var(--m-font);
  margin-bottom: 0.4vw;
}
