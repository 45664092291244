@import "../../../../general.scss";
.menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 3vw;
  padding-left: 5.5vw;
}

.navbar-item {
  @include common-font-style(1.2vw, var(--prime-blue), normal);
  text-transform: capitalize;
  text-decoration: none;
}

.active-style{
  color: var(--white);
}

// @media (max-width: 1000px) {
//   .navbar-item {
//     font-size: var(--xl-font);
//   }
// }