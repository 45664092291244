@import "../../../../../general.scss";
.file-info-input-box {
  @include flex-prop(start, center, 1.5vw);
  margin-left: 2.2vw;
  margin-left: 2.2vw;
}

.file-content-tab-section {
  display: none;
}

.search-file-btn {
  width: 12vw !important;
}

.file-info-inputbox {
  background: none;
  border: none;
  color: var(--white);
  padding: 0.5vw;
  width: 42vw;
  outline: none;
  font-size: var(--m-font);
  font-style: italic;
  border-bottom: 2px solid var(--prime-blue);
}
::placeholder {
  color: var(--white);
}

.file-info-titles {
  margin-top: var(--l-font);
  @include grid-four-col(1.5fr, 0.3fr, 0.3fr, 0.3fr);
  padding-left: 2vw;
  div {
    @include common-font-style(1.4vw, var(--prime-heading), normal);
  }
}

.file-info-titles div:nth-child(2) span {
  font-size: 0.8vw;
  padding-left: 0.5vw;
  color: var(--prime-blue);
  cursor: pointer;
}

.file-info-list {
  overflow-y: scroll;
  min-height: 40vh;
  max-height: 40vh;
}

.file-info-list-item {
  padding-left: 2vw;
  @include grid-four-col(1.5fr, 0.3fr, 0.3fr, 0.3fr);
  border-bottom: 2px solid var(--dark-grey);
  position: relative;
  div {
    @include common-font-style(1.2vw, var(--white), normal);
    padding-left: 0.5vh;
  }
}

.file-info-title {
  @include common-font-style(1.4vw, var(--prime-heading), normal);
}

.file-info-list-item::after {
  content: "";
  position: absolute;
  top: 1vw;
  width: 0.8vw;
  height: 0.8vw;
  background-color: var(--white);
  border-radius: 50%;
}

.file-download-link a,
.file-copy-link a {
  text-decoration: none;
  color: var(--prime-blue);
  cursor: pointer;
}

.file-download-link button {
  background: none;
  border: none;
  font-size: var(--l-font);
}

.filter-icon-btn {
  background: none;
  border: none;
  color: var(--prime-blue);
  padding-left: 0.5vh;
  cursor: pointer;
  font-size: var(--m-font);
}
