@import "../../../../../general.scss";

.search-word-count-tab-section {
  display: none;
}

.search-word-count-section {
  display: flex;
  flex-direction: column;
  // overflow-y: auto;
  overflow: hidden;
}

.word-count-result-section {
  margin: 2.2vw 0 0 2.2vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.word-count-titles {
  @include grid-three-col(1fr, 0.2fr, 0.3fr);
  padding-left: 2vw;
  div {
    @include common-font-style(1.4vw, var(--prime-heading), normal);
  }
}

.word-count-titles div:last-child span {
  font-size: 0.8vw;
  padding-left: 0.5vw;
  color: var(--prime-blue);
  cursor: pointer;
}

.word-count-list {
  overflow-y: scroll;
  min-height: 35vh;
  max-height: 35vh;
}

.text-data-left {
  text-align: left;
}

.link-item a {
  color: var(--white);
  text-decoration: none;
}

.word-count-list-item {
  padding-left: 2vw;
  @include grid-three-col(1fr, 0.2fr, 0.3fr);

  border-bottom: 2px solid var(--dark-grey);
  position: relative;
  padding-bottom: 0.5vh;
  div {
    @include common-font-style(1.2vw, var(--white), normal);
    padding-left: 0.5vh;
    word-break: break-word;
  }
  // div:last-child {
  //   display: flex;
  //   flex-wrap: wrap;
  //   align-items: center;
  //   justify-content: center;
  // }
}

.word-count-list-item::after {
  content: "";
  position: absolute;
  top: 1vw;
  width: 0.8vw;
  height: 0.8vw;
  background-color: var(--white);
  border-radius: 50%;
}

.result-info-title {
  width: 10vw;
}

.filter-icon-btn {
  background: none;
  border: none;
  color: var(--prime-blue);
  padding-left: 0.5vh;
  cursor: pointer;
  font-size: var(--m-font);
}
