@import "../../../../../general.scss";
#file-filter-tab-section {
  background-color: var(--nav-color);
  width: 12vw;
  min-height: 12.5vh;
  border-radius: 5px;
  padding: 0 var(--xxs-font);

  position: absolute;
  top: 24vw;
  right: 30vw;
  z-index: 1;
}

.file-filter-header {
  @include flex-prop(space-between, center, 0vw);
}

.file-filter-icon {
  margin-right: 0.3vw;
  @include common-font-style(var(--xs-font), var(--prime-heading), normal);
}

.file-filter-title {
  @include common-font-style(var(--sm-font), var(--prime-heading), normal);
}

.file-cross-icon {
  @include common-font-style(var(--m-font), var(--prime-blue), normal);
  display: grid;
  cursor: pointer;
}

.file-select-option {
  @include flex-prop(space-between, center, 0vw);
  @include common-font-style(var(--xm-font), var(--prime-blue), normal);
}

.check-icon{
  // font-weight: bold;
  font-weight:bold;
  width: var(--s-font);
  position: absolute;
  z-index: -2;
  left: 0.5vw;
}
.showHide{
  display: none;
}

.chkbx-label{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5vw;
}


.file-phrases-list {
  @include flex-prop(flex-start, center, 0.5vw);
  @include common-font-style(var(--xm-font), var(--white), normal);
  .file-type{
    font-size: var(--s-font);
    color: var(--white );
  }
}

.filter-btn {
  cursor: pointer;
  border: none;
  font-size: 1vw;
  margin-top: 0.4vw;
  border-radius: 10px;
  background-color: var(--prime-blue);
  padding: var(--xs-font);
  @include common-font-style(var(--xm-font), var(--white), normal);
}


.filter-ctrl{
  appearance: none;
  margin: 0;
  font-size: var(--m-font);
  color: #3cc3f2;
  width: 1em;
  height: 1em;
  border: 0.15em solid #3cc3f2;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  position: relative;
  z-index: 1;
}

.filter-ctrl::before{
  content: "";
  width: 0.65em;
  height: 0.65em;
}