.info-table-container {
  width: 60vw;
  height: 100%;
  margin: auto;
}

.scrollable-table {
  width: 100%;
  height: 400px; // Adjust this based on your preferred height
  overflow-y: auto;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  thead {
    position: sticky;
    top: 0;
    background-color: transparent; // Or any color that suits your design
  }

  tbody {
    display: block;
    max-height: 300px; // Adjust this height based on your design
    overflow-y: scroll;
    width: 100%;
  }

  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  th,
  td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
}
