.checkbox {
  display: block;
  position: relative;
  padding: 0.3vh 0 0 3.5vh;
  color: var(--white);
  cursor: pointer;
  font-size: var(--ml-font);
  user-select: none;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 15%;
  left: 0;
  height: 20px;
  width: 20px;
  border: 3px solid #3cc3f2;
}

.checkbox input:checked ~ .checkmark {
  background-color: #3cc3f2;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  left: 2px;
  top: -2px;
  width: 7px;
  height: 12px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
