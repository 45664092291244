@import "../../../../general.scss";

.history-tab-section {
  width: 100vw;
  height: 92vh;
  background-color: #0a1528;
}

.history-tab-center-box {
  // border: 2px solid red;
  width: 100%;
  height: 100%;
  padding: 4vh 2vh 2vh 5vh;
  .history-text {
    color: var(--prime-blue);
    text-transform: uppercase;
    font-size: var(--ml-font);
    // margin-bottom: var(--ml-font);
    position: relative;
    // left: -2vh;
    margin-bottom: 2vh;
  }
}
.history-tab-center-box-section {
  // display: flex;
  position: relative;
  padding: 1vh 1vh;
}
.history-tab-header {
  @include grid-five-col(12vw, 23vw, 15vw, 18vw, 13vw);
  padding: 0 0 1vh 1vh;

  text-align: left;
  h3 {
    @include common-font-style(var(--m-font), #fff, normal);
    text-decoration: underline;
    // text-align: left;
  }
}
