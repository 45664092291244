@import "../.././../general";

.inputContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1vh;
  width: 100%;
  font-size: var(--s-font);
  font-weight: normal;
  color: var(--dark-blue);
  flex-wrap: wrap;
  width: 100%;
  text-align: left;

  .uploadBox {
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    border: "none";
    background-color: var(--dark-grey);
    border-radius: 4px;
    min-height: 38px;
    max-height: 38px;
    overflow-y: hidden;
    overflow-x: hidden;

    .file_upload_button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 1vh;
      width: auto;
      position: absolute;
      color: var(--prime-blue);
      top: 1vh;
      right: 1vh;

      svg {
        cursor: pointer;
      }
    }
    .file_section {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 0.5vh;
      padding: 0 0.5vh;
      min-height: 38px;
      text-align: center;
      // max-height: 4.4vw;
      width: 92%;
      .no-file-chosen {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1vh;
        width: auto;
        font-size: var(--s-font);
        font-weight: normal;
        padding-left: 0.5vh;
        color: hsl(0, 0%, 70%);
      }

      .filename {
        align-self: center;
        color: var(--white);
        display: flex;
        padding-left: 0.5vh;
        font-size: var(--ss-font);
      }
    }
  }
}

.tmtb-upload-user-section {
  @include verticalFlex($flexDirec: column);
  width: 100%;

  .tmtb-upload-user-container {
    @include verticalFlex($flexDirec: column);
    width: 100%;

    .tmtb-upload-user-container-head {
      @include verticalFlex($flexDirec: row);
      justify-content: space-between;
      width: 100%;

      .tmtb-upload-user-container-head-heading {
        color: var(--prime-heading);
        font-size: var(--ml-font);
      }
      .tmtb-upload-user-container-head-add-btn {
        display: flex;
        padding: 1vh 0 1vh 2vh;
        border-radius: 5px;
        border: none;
        outline: none;
        cursor: pointer;
        text-decoration: none;
        font-size: var(--xl-font);
        color: #3cc3f2;
      }
    }

    .container-wrapper {
      @include verticalFlex();
      width: 100%;
      height: 15vh;
      // min-height: 10vh;
      overflow-y: auto;
    }
    .container-wrapper::-webkit-scrollbar {
      background: transparent;
    }

    .tmtb-upload-user-container-dropdown-section {
      @include verticalFlex($flexDirec: row);
      width: 100%;
      justify-content: space-between;

      .tmtb-upload-user-container-dropdown-section-upload {
        width: 100%;
        .tmtb-upload-user-container-dropdown-section-upload-label-head {
          @include verticalFlex($flexDirec: column);
          width: 100%;
          gap: 0.5vh;
          align-items: flex-start;
          .label-with-tip {
            @include verticalFlex($flexDirec: row);
            .dropdown-label {
              color: var(--prime-heading);
              font-size: var(--m-font);
            }
            .show-tip-icon {
              color: var(--prime-heading);
              font-size: var(--xs-font);
            }
            .show-tip-icon:hover + .show-tip {
              visibility: visible;
            }
            .show-tip {
              visibility: hidden;
              color: white;
              background-color: var(--prime-blue);
              font-size: var(--s-font);
              position: absolute;
              z-index: 9999;
              top: -2vh;
              left: 10vh;
              padding: 0.5vh 1vh;
            }
          }
        }
      }
      .tmtb-upload-user-container-dropdown-section-domain {
        width: 50%;
        .select-container {
          @include verticalFlex($flexDirec: column);
          gap: 0.5vh;
          .select-form-label {
            font-size: var(--m-font);
          }
        }
      }
    }
  }
}
