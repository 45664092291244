@import "../../../general";

.editor-upload-assign-logo {
  position: relative;
  display: flex;
  grid-template-columns: repeat(2, min-content);
  align-items: center;
  height: fit-content;
  column-gap: 1vh;
}

.editor-upload-assign-logo img {
  display: inline-block;
  width: 2.8vw;
  height: 7vh;
  margin-right: 10px;
}

.assgnname {
  position: relative;
  display: inline-flex;
  word-wrap: break-word;
  word-break: break-all;
  color: var(--orange-color);
  max-width: 100%;
  vertical-align: top;
  font-size: var(--m-font);
  width: max-content;
  cursor: pointer;
}

.assgnname-active {
  color: var(--prime-heading);
  position: relative;
  display: inline-flex;
  word-wrap: break-word;
  word-break: break-all;
  max-width: 100%;
  vertical-align: top;
  font-size: var(--m-font);
  width: max-content;
  cursor: pointer;
}

.editor-upload-section-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.editor-upload-section {
  @include verticalFlex($flexDirec: column);
  width: 100%;
  margin-top: 5vh;

  .editor-upload-top-container {
    @include verticalFlex($flexDirec: column);
    width: 60%;
    gap: 2vh;
    justify-content: center;
    align-items: baseline;

    .editor-upload-assign-selection-container {
      //   @include verticalFlex($flexDirec: column);
      gap: 3vh;
      display: flex;
      flex-direction: column;
      width: 100%;

      .editor-upload-assign-selection-container-dropdown {
        .select-container {
          @include verticalFlex($flexDirec: column);
        }
      }

      .editor-upload-assign-selection-container-logo {
        @include verticalFlex($flexDirec: row);
      }
    }

    .editor-upload-assign-info-container {
      @include verticalFlex($flexDirec: column);
      align-items: baseline;
      color: white;
      width: 100%;

      .editor-upload-assign-info-container-box-grid {
        @include grid-three-col(1fr, 1fr, 1.2fr);
        gap: 15vw;
        width: 100%;

        .editor-upload-assign-info-container-box-element {
          display: flex;
          flex-direction: column;
          gap: 1vh;

          .editor-upload-assign-info-label {
            color: var(--prime-heading);
            font-size: var(--m-font);
          }
          .editor-upload-assign-info-data {
            color: var(--white);
            font-size: var(--m-font);
          }
        }

        // .first-col {
        //   align-items: start;
        // }
        // .second-col {
        //   align-items: center;
        // }
        // .third-col {
        //   align-items: end;
        // }
      }
    }

    .editor-upload-assign-text-input-container {
      @include verticalFlex($flexDirec: column);
      align-items: baseline;
      width: 100%;

      .editor-upload-assign-text-input-label {
        @include verticalFlex($flexDirec: column);
        color: var(--prime-heading);
        font-size: var(--m-font);
      }

      .editor-upload-assign-text-input-data {
        width: 100%;
      }

      .editor-upload-assign-text-input-data input {
        @include verticalFlex($flexDirec: column);
        color: white;
        outline: none;
        font-size: var(--s-font);
        padding: 0.8vh;
        height: 38px;
        width: 100%;
        border-radius: 5px;
        background: var(--dark-grey);
        border: none;
      }

      .editor-upload-assign-text-input-data input::placeholder {
        color: hsl(0, 0%, 70%);
      }
    }
  }

  .editor-upload-bottom-container {
    // margin-top: 3vh;
    @include verticalFlex($flexDirec: column);
    align-items: baseline;
    width: 100%;

    // .file_upload_section {
    //   min-height: 10vw;
    // }

    // .termBase_upload_button {
    //   // @include editor-btn($width: 100%);
    //   transition: background-color 0.01s linear;
    //   .loader-container {
    //     height: fit-content;
    //   }
    // }
    // .termBase_upload_button:disabled {
    //   background-color: var(--shadow--1);
    // }

    // .disabled_upload_button {
    //   width: 100%;
    //   height: 2.65vw;
    //   border-radius: var(--TBTBBorder-radius);
    //   font-size: var(--ml-font);
    //   color: var(--white-color);
    //   background-color: var(--shadow--1);
    // }
    // .loader-container {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   height: 10vh;
    // }

    // .faded {
    //   background-color: rgba(125, 208, 244, 0.8);
    // }

    // .loader {
    //   border: 2px solid #f3f3f3;
    //   border-top: 2px solid var(--light-blue);
    //   border-radius: 50%;
    //   width: 2vh;
    //   height: 2vh;
    //   animation: spin 0.5s linear infinite;
    // }

    // @keyframes spin {
    //   0% {
    //     transform: rotate(0deg);
    //   }
    //   100% {
    //     transform: rotate(360deg);
    //   }
    // }
  }

  .editor-upload-submit-btn {
    display: flex;
    justify-content: flex-end;
    min-width: 100%;

    .sbmt-btn {
      @extend .tool-btn;
      background-color: var(--prime-blue);
      font-size: var(--m-font);
      min-width: 100%;
      &:disabled {
        background-color: var(--dark-grey);
      }
    }
  }
}

.editor-upload-assign-selection-note{
  margin-top: 0.5vh;
  color: var(--prime-heading);
  font-size: var(--m-font);
}