@import "./../../../general.scss";

.slide_level_table_data_container {
  padding-top: 2vh;
  width: 60vw;
  max-height: 500px;
  background-color: #0a1528;

  .slide_page_level_heading {
    cursor: pointer;
    background-color: #0a1528;
    @include common-font-style(var(--ml-font), var(--prime-blue), normal);
    text-align: center;
  }

  .slide-wrapper {
    max-height: 0;
    transition: max-height 0.3s ease-out;
    &.open {
      max-height: 300px;
    }

    .new_slide_level_heading_alignment {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2vh;
      justify-content: center;

      .slide_page_level_heading {
        cursor: pointer;
        background-color: #0a1528;
        @include common-font-style(var(--ml-font), var(--prime-blue), normal);
        text-align: center;
      }
    }

    .slide_level_table_scrollable {
      background-color: var(--backg-color);
      position: sticky;
      top: 0;
      height: 33vh;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }

      .slide_page_level_information_table {
        overflow-y: auto;
        width: 100%;
        background-color: #0a1528;
        max-height: 400px;

        .slide_page_level_cols_data {
          @include common-font-style(var(--l-font), var(--prime-blue), normal);
          position: sticky;
          top: 0;
          background-color: #0a1528;
        }
      }
    }
  }
}

.go_back_icon {
  cursor: pointer;
  color: var(--prime-blue);
  font-size: 2vh;
}
