@import "../../../../../../_general.scss";
.history-pagination {
  @include flex-prop(space-between, center, 0vw);
  padding: 5vh 0 0 0;
  text-align: center;
  button {
    display: flex;
    align-items: center;
    gap: 0.2vw;
    background: none;
    border: none;
    color: var(--prime-blue);
    cursor: pointer;
    font-size: 1.35rem;
    font-family: "Louis-Geroge-Cafe";
  }
  span {
    color: var(--prime-blue);
    font-size: var(--ml-font);
    padding-top: 2px;
  }
}
