@import "../../general.scss";

#tool-main-section {
  background: rgba(241, 247, 249, 0.15);
  color: #fff;
  backdrop-filter: blur(10px);
}

#header-section {
  display: grid;
  grid-template-columns: 0.15fr 1fr 0.1fr;
  padding: 0.5vw 0;
}

.tool-logo {
  font-size: var(--l-font);
  .logo-img {
    height: 2.5vw;
  }
}
