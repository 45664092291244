@import "../../general";

.login-container {
  background: var(--backg-color);
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .login-body-container {
    width: 40vh;

    .login-body-logo-container {
      display: flex;
      width: 100%;
      align-items: flex-start;
    }

    .login-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 2vh;

      .login-form-btn-container {
        display: flex;
        align-self: flex-end;
        .login-sbmt-btn {
          @extend .tool-btn;
          background-color: var(--prime-blue);
          font-size: var(--m-font);
        }
      }

      .login-form-input-container {
        margin: 2% 0%;

        .login-form-input-box {
          .login-form-input-label {
            color: #fff;
            font-size: var(--m-font);
            padding-left: 0.5vh;
          }

          .login-form-input {
            width: 100%;
            padding: 3% 3%;
            margin: 3% 0%;
            box-sizing: border-box;
            border-radius: 8px;
            border: none;
          }

          .login-form-input-password {
            display: flex;
            flex-direction: column;
            position: relative;
          }
        }
        .pswd-show-btn {
          position: absolute;
          display: flex;
          align-items: center;
          right: 0.3vh;
          padding: 2vh;
          font-size: var(--m-font);
          background: transparent;
          outline: none;
          border: none;
          cursor: pointer;
          color: #000;
        }
      }
    }
  }
}
