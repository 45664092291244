@import "../../../general.scss";

.loader-modal {
  @include box-center;
  position: relative;
  // width: 85vw;
  // height: 60vh;
}
.loader-modal div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 6%;
  // height: 16%;
  height: 8vh;
  margin: var(--xxs-font);
  border: var(--xxs-font) solid var(--white);
  border-radius: 50%;
  animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--prime-blue) transparent transparent transparent;
}
.loader-modal div:nth-child(1) {
  animation-delay: -0.45s;
}
// .loader-modal div:nth-child(2) {
//   animation-delay: -0.3s;
// }
// .loader-modal div:nth-child(3) {
//   animation-delay: -0.15s;
// }
@keyframes loader-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
