@import "../../../general.scss";

#word-count-container {
  background-color: var(--backg-color);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.word-count-box {
  padding-top: 2vh;
  width: 100%;
  height: 100%;
  line-height: 1.8;
  flex-direction: column;
  display: flex;
}

.word-count-info-header,
.result-info-title {
  @include common-font-style(1.4vw, var(--prime-heading), normal);
}

.word-count-url-container {
  @include grid-three-col(0.02fr, 0.3fr, 1fr);
}

.search-tag-container {
  margin-left: 2.2vw;
  @include grid-two-col(0.3fr, 1fr);
}

.go-back-context {
  padding-right: 0.8vw;
  @include box-center;
  cursor: pointer;
  .go-back-icon {
    @include common-font-style(1.6vw, var(--prime-blue), normal);
  }
}

.word-count-info-title {
  @include common-font-style(1.4vw, var(--white), normal);
}

.word-count-tab-button-container {
  @include flex-prop(space-between, center, 0.4vw);
  padding: 0.8vw;
  margin: var(--m-font) var(--m-font) var(--m-font) 2.2vw;
  background: var(--nav-color) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.word-count-tab-container {
  @include flex-prop(center, center, 2vw);
  @include common-font-style(1.2vw, var(--prime-blue), normal);
  cursor: pointer;
}

.tool-dwnload-btn {
  font-size: var(--m-font);
  padding: 0.75vw 1.25vw;
  border-radius: 5px;
  background-color: var(--prime-blue);
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: white;
  min-width: 6vw;

  &:hover {
    opacity: 0.8;
  }
}

.search-phrase-input-box,
.search-link-input-box {
  @include flex-prop(flex-start, center, 2.6vw);
  margin-left: 2.2vw;
}

.result-info-inputbox {
  background: none;
  border: none;
  color: var(--white);
  padding: 0.5vw;
  width: 52vw;
  outline: none;
  font-size: var(--m-font);
  font-style: italic;
  border-bottom: 2px solid var(--prime-blue);
}
::placeholder {
  color: var(--white);
}

.spcl-btn {
  background: none;
  border: none;
  color: var(--prime-blue);
  cursor: pointer;
  font-size: var(--l-font);
  font-family: "Louis-Geroge-Cafe";

  width: 15vw;
  text-align: right;
}

// tab active css style here +++++++++++++++++++++++
.active-tab {
  color: var(--white);
}

.active-content {
  display: block;
}
