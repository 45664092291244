@import "../../../../../general.scss";
#word-count-filter-section {
  background-color: var(--nav-color);
  width: 12vw;
  min-height: 16vh;
  border-radius: 5px;
  padding: 0 var(--xxs-font);

  position: absolute;
  top: 25vw;
  right: 5vw;
  z-index: 1;
}

.word-count-filter-header {
  @include flex-prop(space-between, center, 0vw);
}

.word-count-filter {
  margin-right: 0.3vw;
  @include common-font-style(var(--s-font), var(--prime-heading), normal);
}

.word-count-filter-title {
  @include common-font-style(var(--m-font), var(--prime-heading), normal);
}

.word-count-cross {
  @include common-font-style(var(--m-font), var(--prime-blue), normal);
  display: grid;
  cursor: pointer;
}

.word-count-select-option {
  @include flex-prop(space-between, center, 0vw);
  @include common-font-style(var(--xm-font), var(--prime-blue), normal);
  span {
    cursor: pointer;
  }
}

.phrase-item {
  width: 0.75em !important;
  height: 0.75em !important;
}

.phrases-list {
  @include flex-prop(flex-start, center, 0.5vw);
  @include common-font-style(var(--m-font), var(--white), normal);

  span {
    align-items: center;
  }
}

.filter-btn {
  cursor: pointer;
  border: none;
  width: 11vw;
  margin-top: var(--xs-font);
  border-radius: 10px;
  background-color: var(--prime-blue);
  padding: var(--xs-font);
  @include common-font-style(var(--xm-font), var(--white), normal);
}
.filter-box:checked::before {
  font-size: 1vw !important;
}

.chck-icon {
  font-weight: bold;
  width: 0.5vw;
  position: absolute;
  z-index: -2;
  left: 0.5vw;
}

.loading-style {
  text-align: center;
}
