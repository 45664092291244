@import "../../../general";

.count-upload-container {
  height: 91.5vh;
  width: 100%;
  // overflow: auto;
  padding: 3vh 0vh;
  position: relative;
  display: flex;
  justify-content: center;

  .count-upload-section {
    width: 100vw;
    position: relative;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    // gap: 20vw;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row-reverse;

    .count-upload-history {
      // width: 10vh;
      color: var(--prime-blue);
      font-size: var(--ml-font);
      text-decoration: none;
      position: absolute;
      z-index: 2;
      right: 0vh;
      display: flex;
    }

    .count-upload-form-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2vh;
      width: 40vw;

      .drag-n-drop-upload {
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 0.5vh;
        flex-direction: row;
        position: relative;

        .cancel-icon {
          color: var(--orange-color);
          font-size: var(--m-font);
          cursor: pointer;
          position: absolute;
          right: -3vh;
        }
      }

      .count-upload-form {
        width: 100%;
        align-self: center;
        display: flex;
        flex-direction: column;
        gap: 3vh;

        .top-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 2vh;

          .dropdown-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 22vw;
            gap: 2vh;
          }

          .form-sbmt-btn {
            display: flex;
            justify-content: flex-end;

            .sbmt-btn {
              @extend .tool-btn;
              background-color: var(--prime-blue);
              font-size: var(--m-font);
            }
          }
        }

        .include_wc_container {
          display: flex;
          width: max-content;
          gap: 10vh;
          font-size: var(--ml-font);
          color: white;

          .include_wc_container_sc {
            display: flex;
            flex-direction: column;
            width: fit-content;
            gap: 1vh;
            width: 15vw;

            .checkbox {
              display: block;
              position: relative;
              padding-left: 35px;

              cursor: pointer;
              font-size: var(--ml-font);
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
              user-select: none;
            }

            .checkbox input {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
            }

            .checkmark {
              position: absolute;
              top: 15%;
              left: 0;
              height: 20px;
              width: 20px;
              border: 3px solid #3cc3f2;
            }

            .checkbox input:checked ~ .checkmark {
              background-color: #3cc3f2;
            }

            .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }

            .checkbox input:checked ~ .checkmark:after {
              display: block;
            }

            .checkbox .checkmark:after {
              left: 2px;
              top: -2px;
              width: 7px;
              height: 12px;
              border: solid #000;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }
      }

      .checkbox-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2vh;

        .checkbox-container-heading {
          color: var(--prime-heading);
          font-size: var(--ml-font);
        }
      }
    }
  }
}
