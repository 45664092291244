@import "../../../general";

.count_history_container {
  padding-top: 4vh;
  height: 92.5vh;
  background-color: #0a1528;
  color: aliceblue;
  display: flex;
  gap: 2vh;
  flex-direction: column;
}
.count_history_content_inner {
  width: 80vw;
  // overflow: auto;
  margin: 0 auto;
}
.count-history-heading {
  color: var(--prime-blue);
  font-size: var(--ml-font);
  display: flex;
  padding-left: 19vh;
}
.pagination {
  /* height: 15vh; */

  // padding: 3% 0px;
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  @include flex-prop(space-between, center, 0vw);
  padding: 3% 0px;
  text-align: center;
  button {
    display: flex;
    gap: 0.2vw;
    background: none;
    border: none;
    align-items: center;
    color: var(--prime-blue);
    cursor: pointer;
    font-size: 1.35rem;
    font-family: "Louis-Geroge-Cafe";
  }
  span {
    color: var(--prime-blue);
    font-size: var(--ml-font);
  }
}
// .pagination_btn {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 10vw;
//   height: 4vh;
//   border: none;
//   border-radius: 10px;
//   color: white;
//   font-size: var(--m-font);
//   background-color: #3cc3f2;
//   cursor: pointer;
// }

.count_history_content_heading_head {
  padding: 1vh;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
  grid-gap: 2rem;
  grid-template-areas: "date file_name total_file_wc src_file_wc file_dwnld";
}

.count_history_content_heading {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  min-height: 60vh;
}

.table_heading {
  font-size: var(--m-font);
  text-decoration: underline;
}
.mobile_view_hide_heading {
  display: none;
}

.count_row_card {
  padding: 1vh;
  gap: 2rem;
  font-size: var(--m-font);
  cursor: pointer;

  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
  grid-template-areas: "date file_name src_file_wc total_file_wc file_dwnld";
}

#file_name {
  grid-area: file_name;
  word-break: break-all;
}
#total_file_wc {
  grid-area: total_file_wc;
  word-break: break-all;
}
#src_file_wc {
  grid-area: src_file_wc;
  word-break: break-all;
}

.glass-container {
  color: var(--white, #ffffff);
  background: rgba(241, 247, 249, 0.15);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 1vh;
}

.glass-container-hover:hover {
  color: var(--white, #ffffff);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  background: rgba(241, 247, 249, 0.2);
}

.glass-container-active {
  color: var(--white, #ffffff);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  /* background: rgba( 241, 247, 249, 0.25 ); */
  background: rgba(9, 21, 38, 0.4);
  /* opacity: 0.8; */
}
@media (min-width: 768px) and (max-width: 1366px) {
  .table_heading {
    font-size: var(--m-font);
    text-decoration: underline;
  }
}
@media (min-width: 1366px) and (max-width: 1600px) {
  .table_heading {
    font-size: var(--m-font);
    text-decoration: underline;
  }
}
@media (max-width: 468px) {
  .count_history_content_inner {
    width: 100vw;
  }
  .pagination {
    width: 100%;
  }
  .count_row_card {
    gap: 1rem;
    font-size: 1.5vh;

    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      "date src_file_wc"
      "file_name total_file_wc";
  }
  .table_heading {
    font-size: 1.7vh;
    text-decoration: underline;
  }
  .count_history_content_heading_head {
    display: none;
  }
  .count_history_content_heading {
    height: 80vh;
    overflow-y: auto;
  }
  .mobile_view_hide_heading {
    display: block;
    padding: 1vh 0;
    text-decoration: none;
  }
  .pagination_btn {
    padding: 1vh 12vw;

    border-radius: 1vw;
  }
}
/* @media (max-width: 375px){
   
    .count_row_card{
       
        gap: 1rem;
        font-size: 1.5vh;
        
        grid-template-columns: 2fr 1fr;
        grid-template-areas: 
        'date src_file_wc'
        'file_name total_file_wc';
        
    }
    .table_heading{
        font-size: 1.7vh;
        text-decoration: underline;
    }
    .count_history_content_heading{
        height: 80vh;
        overflow-y: auto;
    }
    .count_history_content_heading_head{
        display: none;
    }
    .mobile_view_hide_heading{
        display: block;
        padding: 1vh 0;
        text-decoration: none;
    }
    .pagination_btn{
       
        padding: 1vh 12vw;
        
        border-radius: 1vw;
    }
    
} */
