.history-btn-section {
  position: relative;
  top: 3vh;
  flex-direction: row-reverse;
  display: flex;
}

.history-btn {
  background: none;
  border: none;
  color: var(--prime-blue);
  cursor: pointer;
  font-size: var(--ml-font);
  font-family: "Louis-Geroge-Cafe";
  text-transform: uppercase;
}
