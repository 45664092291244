@import "../../../../general.scss";

.search-form-main-section {
  @include box-center;
  height: 85vh;
}

.search-form-center-box {
  width: 60vw;
  display: flex;
  flex-direction: column;
  gap: 3vh;
}

.search-form-url-section {
  @include grid-two-col(0.75fr, 2.5fr);
  // margin-bottom: 2vh;
}

.search-form-radio-section {
  @include grid-four-col(auto, auto, auto, auto);
  gap: 5vw;
}

.search-form-check-section {
  @include flex-prop(space-between, center, 3vw);
}

.search-form-label-section {
  display: flex;
  align-items: center;
  @include common-font-style(var(--ml-font), var(--prime-heading), normal);
}

.user-email-input {
  background: none;
  border: none;
  color: var(--white);
  padding: 0 0 0.2vw 0;
  width: 45vw;
  outline: none;
  font-size: var(--m-font);
  font-style: italic;
  border-bottom: 2px solid var(--prime-blue);
  // margin-bottom: 2vh;
  &::placeholder {
    font-size: var(--s-font);
    color: var(--white);
  }
}

.user-keyword-input {
  background: none;
  border: none;
  color: var(--white);
  width: 45vw;
  outline: none;
  font-size: var(--m-font);
  font-style: italic;
  padding: 0.3vh 0 0.2vw 0;
  pointer-events: none;
  border-bottom: 2px solid var(--dark-grey);
  &::placeholder {
    font-size: var(--s-font);
    color: var(--dark-grey);
  }
}

.user-keyword-input-active {
  border-bottom: 2px solid var(--prime-blue);
  pointer-events: auto;
  color: "white";
  &::placeholder {
    color: var(--white);
  }
}
.user-keyword-input-inactive {
  color: var(--dark-grey);
  border-bottom: 2px solid var(--dark-grey);
  pointer-events: none;
  &::placeholder {
    color: var(--dark-grey);
  }
}

.search-checkbox-label {
  width: 15vw;
}

.search-form-search-section {
  @include grid-two-col(auto, auto);
}

#search-btn {
  display: flex;
  justify-content: flex-end;
  button {
    padding: 0.5vw 1vw;
    font-size: 1vw;
    cursor: pointer;
    @include box-center;
  }
}

/* Radio Btn customize */

input[type="radio"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  cursor: pointer;
  color: #3cc3f2;
  width: 1.15em;
  height: 1.15em;
  border: 0.2em solid currentColor;
  border-radius: 50%;
  background: none;
  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  cursor: pointer;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #3cc3f2;
}

input[type="radio"]:checked::before {
  transform: scale(1);
  cursor: pointer;
}

.radio-control {
  @include flex-prop(flex-start, center, 3vw);

  gap: 2vw;
  font-size: 1vw;
  color: white;
  gap: 1vw;
}

.radio-text {
  font-size: var(--ml-font);
}
.checkbox-text {
  font-size: var(--ml-font);
}

.search-chkbox-disabled {
  color: var(--dark-grey);
}

.search-form-checkbox-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2vh;
  .checkbox-section-heading {
    color: var(--prime-heading);
    font-size: var(--ml-font);
  }
  .checkbox-section-body-container {
    display: flex;
    width: 100%;
    gap: 10vh;
    font-size: var(--ml-font);
    color: white;
    .checbox-section-body-content {
      display: flex;
      flex-direction: column;
      width: fit-content;
      gap: 1vh;
      // width: 15vw;
      .search-form-phrase-checkbox-container {
        @include grid-two-col(1fr, 1fr);
        // margin-bottom: 2vh;
      }
    }
  }
  .search-form-phrase-checkbox-active {
    .checkbox {
      color: var(--white);
    }
    .checkmark {
      border: 3px solid var(--prime-blue);
    }
  }
  .search-form-phrase-checkbox-inactive {
    .checkbox {
      color: var(--dark-grey);
    }
    .checkmark {
      border: 3px solid var(--dark-grey);
    }
  }
}
