$primary-color: var(--prime-blue);
$hover-color: #2563eb;
$disabled-color: #d1d5db;
$text-color: #374151;
$background-color: #707070;
$border-color: #d1d5db;

.dropdown-container {
  position: relative;
  width: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.dropdown-button {
  width: 100%;
  padding: 0.5rem 1rem;
  text-align: left;
  background-color: $background-color;
  border: none;
  outline: none;
  border-radius: 0.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: lighten($background-color, 5%);
  }

  &.open {
    border-color: $primary-color;
  }
}

.dropdown-label {
  color: #ffffff;
  font-size: var(--m-font);
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  transition: transform 0.2s ease-in-out;
  fill: $primary-color;

  &.rotate {
    transform: rotate(180deg);
  }
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 0.25rem;
  background-color: $background-color;
  border: none;
  outline: none;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.menu-options {
  padding: 0.5rem 0;
}

.menu-option {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: lighten($background-color, 5%);
  }

  .checkbox-container {
    .checkbox {
      font-size: var(--m-font);
    }
  }
}

.menu-footer {
  border-top: 1px solid $border-color;
  padding: 0.5rem 1rem;
}

.download-button {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  color: #0a1528;
  background-color: $primary-color;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:disabled {
    background-color: $disabled-color;
    cursor: not-allowed;
  }

  .download-icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
  }
}
