@import "../../../../general.scss";
.logout-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    background: none;
    border: none;
    color: var(--prime-blue);
    cursor: pointer;
    font-size: var(--l-font);
    font-family: "Louis-Geroge-Cafe";
  }
}
