@import "../../../general";

.flip-upload-container {
  height: 91.5vh;
  width: 100%;
  padding: 3vh 0vh;
  position: relative;
  display: flex;
  justify-content: center;

  .flip-upload-section {
    width: 100vw;
    position: relative;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row-reverse;

    .flip-upload-history {
      // width: 10vh;
      position: absolute;
      color: var(--prime-blue);
      font-size: var(--ml-font);
      text-decoration: none;
      z-index: 2;
      display: flex;
      right: 0vh;
    }

    .flip-upload-form-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2vh;
      width: 40vw;

      .drag-n-drop-upload {
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 0.5vh;
        flex-direction: row;
        position: relative;

        .cancel-icon {
          color: var(--orange-color);
          font-size: var(--m-font);
          cursor: pointer;
          position: absolute;
          right: -3vh;
        }
      }

      .flip-upload-form {
        width: 100%;
        height: 7vh;
        align-self: center;
        display: flex;
        flex-direction: column;
        gap: 5vh;

        .top-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 2vh;
          // height: 7vh;

          .flip-file-info-container {
            gap: 1vh;
            flex-direction: column;
            display: flex;

            .flip-file-info-heading {
              font-size: var(--ml-font);
              color: white;
            }
            .flip-file-info-value {
              width: 24vh;
              font-size: 1.4rem;
              color: #3cc3f2;
            }
          }

          .flip-file-info-value a {
            text-decoration: none;
            color: var(--prime-blue);
          }

          .form-sbmt-btn {
            display: flex;
            justify-content: flex-end;

            .sbmt-btn {
              @extend .tool-btn;
              background-color: var(--prime-blue);
              font-size: var(--m-font);
            }

            .submit-btn-disabled {
              @extend .tool-btn;
              background-color: var(--dark-grey);
              font-size: var(--m-font);
            }
          }
        }
      }
    }
  }
}
