@import "./../../general";

.standalone_table_full_container_scrollable {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  margin-bottom: 2vh;

  .full_fledge_page {
    @include verticalFlex();
    justify-content: center;
    padding-top: 5vh;
    gap: 5vh;
    background-color: #0a1528;

    table {
      width: 100%;
      border-collapse: collapse;
    }

    th {
      border-bottom: 1px solid var(--dark-grey);
      padding: 2vh;
      @include common-font-style(var(--m-font), var(--prime-blue), normal);
      text-align: left;
    }

    td {
      border-bottom: 1px solid var(--dark-grey);
      padding: 2vh;
      @include common-font-style(var(--m-font), var(--white), normal);
      text-align: left;
      background-color: #0a1528;
    }

    .part_level_table_data_container,
    .slide_level_table_data_container {
      padding-top: 2vh;
      width: 60vw;
      max-height: 500px;
      background-color: #0a1528;
    }

    .part_level_heading {
      cursor: pointer;
      @include common-font-style(var(--ml-font), var(--prime-blue), normal);
      text-align: center;
    }

    .part_level_information_table {
      overflow-y: auto;
      width: 100%;
      padding-top: 2vh;
    }

    .part_level_cols_data {
      @include common-font-style(var(--l-font), var(--prime-blue), normal);
      background-color: var(--backg-color);
    }

    .slide-wrapper {
      max-height: 0;
      transition: max-height 0.3s ease-out;
    }

    .slide-wrapper.open {
      max-height: 300px;
    }

    .editor-tracker-selection-note {
      margin-top: 0.5vh;
      color: var(--prime-heading);
      font-size: var(--m-font);
    }

    .editor-tracker-container-dropdown {
      width: 60vw;
      display: flex;
      flex-direction: column;
      gap: 1vh;

      .select-container {
        @include verticalFlex($flexDirec: column);
      }
    }

    .workspace_assignment_id {
      @include common-font-style(var(--ml-font), var(--prime-heading), normal);
    }

    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 9999;

      .spinner {
        border: 4px solid #f3f3f3;
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
      }
    }

    .select_input_dropdown_value .css-qbdosj-Input {
      color: transparent;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
